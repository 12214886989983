<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="d-flex flex-column flex-md-row">
      <div class="flex-md-row-auto col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="d-flex justify-content-between flex-column h-100">
              <div
                @click.prevent="addBlock('main-slider')"
                class="mb-5 cursor-pointer border"
              >
                <img
                  src="media/blocks/main-slider.jpg"
                  alt="main-slider"
                  width="100%"
                  title="Main Slider"
                />
              </div>
              <div
                @click.prevent="addBlock('products-slider')"
                class="mb-5 cursor-pointer border"
              >
                <img
                  src="media/blocks/products-slider.jpg"
                  alt="products-slider"
                  width="100%"
                  title="Products Slider"
                />
              </div>
              <div
                @click.prevent="addBlock('latest-news')"
                class="mb-5 cursor-pointer border"
              >
                <img
                  src="media/blocks/latest-news.jpg"
                  alt="latest-news"
                  width="100%"
                  title="Latest News"
                />
              </div>
              <div
                @click.prevent="addBlock('quick-search')"
                class="mb-5 cursor-pointer border"
              >
                <img
                  src="media/blocks/quick-search.jpg"
                  alt="latest-news"
                  width="100%"
                  title="Quick Search"
                />
              </div>
              <div
                @click.prevent="addBlock('product-categories')"
                class="mb-5 cursor-pointer border"
              >
                <img
                  src="media/blocks/product-categories.jpg"
                  alt="latest-news"
                  width="100%"
                  title="Product Categories"
                />
              </div>
              <div
                @click.prevent="addBlock('product-banner')"
                class="mb-5 cursor-pointer border"
              >
                <img
                  src="media/blocks/product-banner.jpg"
                  alt="latest-news"
                  width="100%"
                  title="Product Categories"
                />
              </div>
              <div
                @click.prevent="addBlock('brands')"
                class="mb-5 cursor-pointer border"
              >
                <img
                  src="media/blocks/brands.jpg"
                  alt="brands"
                  width="100%"
                  title="brands"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isPageIsLoaded" class="flex-md-row-fluid col-md-9">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-custom card-sticky gutter-b">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">
                    Edit Page - <span>{{ page.title }}</span>
                  </h3>
                </div>
                <CardToolbar
                  @submitEvent="update"
                  back-text="All Pages"
                  back-url="/pages/list"
                  button-text="Pages"
                />
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>Title<span class="text-danger">*</span></label>
                    <input
                      v-model="page.title"
                      class="form-control form-control-solid"
                      placeholder="Title"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>Slug<span class="text-danger">*</span></label>
                    <input
                      v-model="page.slug"
                      class="form-control form-control-solid"
                      placeholder="Slug"
                    />
                  </div>
                </div>

                <div class="separator separator-dashed mt-4 mb-10"></div>

                <div>
                  <draggable
                    class="list-group"
                    tag="div"
                    v-model="page.configuration.section"
                    v-bind="dragOptions"
                    @start="isDragging = true"
                    @end="isDragging = false"
                  >
                    <transition-group type="transition" name="flip-list">
                      <div
                        class="list-group-item"
                        v-for="(element, key) in page.configuration.section"
                        :key="key"
                      >
                        <div
                          class="list-group-item__header d-flex align-items-center justify-content-between px-5 pt-3"
                        >
                          <span class="font-weight-bolder">{{
                            titleCase(element.name)
                          }}</span>
                          <button
                            @click="toggle(element.slug)"
                            class="btn font-weight-bold btn-light-primary btn-icon ml-auto mr-2"
                          >
                            <i class="la la-edit"></i>
                          </button>
                          <button
                            class="btn font-weight-bold btn-light-primary btn-icon mr-2 cursor-move"
                          >
                            <i class="la la-arrows-alt-v"></i>
                          </button>
                          <button
                            @click="remove(key)"
                            class="btn font-weight-bold btn-danger btn-icon"
                          >
                            <i class="la la-remove"></i>
                          </button>
                        </div>
                        <div
                          v-if="activeBlock === element.slug"
                          class="list-group-item__body"
                        >
                          <div class="row">
                            <div class="col-md-6">
                              <div
                                v-if="
                                  (page.configuration.section[
                                    key
                                  ].hasOwnProperty('payload') &&
                                    activeBlock === 'main-slider') ||
                                    activeBlock === 'products-slider' ||
                                    activeBlock === 'brands' ||
                                    activeBlock === 'latest-news'
                                "
                                class="form-group col-md-12"
                              >
                                <label>Title</label>
                                <input
                                  v-model="
                                    page.configuration.section[key].payload
                                      .title
                                  "
                                  class="form-control form-control-solid"
                                  placeholder="Title"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div
                                v-if="
                                  (page.configuration.section[
                                    key
                                  ].hasOwnProperty('payload') &&
                                    activeBlock === 'main-slider') ||
                                    activeBlock === 'products-slider' ||
                                    activeBlock === 'brands' ||
                                    activeBlock === 'latest-news'
                                "
                                class="form-group col-md-12"
                              >
                                <label>Subtitle</label>
                                <input
                                  v-model="
                                    page.configuration.section[key].payload
                                      .subtitle
                                  "
                                  class="form-control form-control-solid"
                                  placeholder="Subtitle"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              (page.configuration.section[key].hasOwnProperty(
                                'payload'
                              ) &&
                                activeBlock === 'main-slider') ||
                                activeBlock === 'products-slider' ||
                                activeBlock === 'brands'
                            "
                            class="separator separator-dashed mt-1 mb-8"
                          ></div>
                          <div
                            v-if="
                              (page.configuration.section[key].hasOwnProperty(
                                'payload'
                              ) &&
                                activeBlock === 'main-slider') ||
                                activeBlock === 'products-slider'
                            "
                            class="row"
                          >
                            <div class="col-md-12 my-5">
                              <label class="pl-5">Products</label>
                              <DualListBox
                                v-if="isProductsLoaded"
                                :element-key="key"
                                :source="products"
                                :destination="
                                  page.configuration.section[key].payload
                                    .entities
                                "
                                label="name"
                                @onChangeList="onChangeList"
                              />
                            </div>
                          </div>
                          <div
                            v-if="
                              page.configuration.section[key].hasOwnProperty(
                                'payload'
                              ) && activeBlock === 'brands'
                            "
                            class="row"
                          >
                            <div class="col-md-12 my-5">
                              <label class="pl-5">Brands</label>
                              <DualListBox
                                v-if="isBrandsLoaded"
                                :element-key="key"
                                :source="brands"
                                :destination="
                                  page.configuration.section[key].payload
                                    .entities
                                "
                                label="name"
                                @onChangeList="onBrandChangeList"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card card-custom gutter-b">
              <div class="card-body">
                <vue-json-editor
                  v-model="page.configuration"
                  :show-btns="false"
                  mode="code"
                  :expandedOnStart="true"
                >
                </vue-json-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import vueJsonEditor from 'vue-json-editor'
import draggable from 'vuedraggable'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DualListBox from '@/view/content/components/dual-list-box/DualListBox'

export default {
  name: 'EditPage',
  components: {
    CardToolbar,
    vueJsonEditor,
    draggable,
    DualListBox
  },
  data() {
    return {
      page: {
        title: null,
        slug: null,
        configuration: null
      },
      isPageIsLoaded: false,
      activeBlock: null,
      posts: [],
      products: [],
      brands: [],
      isProductsLoaded: false,
      isBrandsLoaded: false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Pages', route: '/pages/list' },
      { title: 'Edit Page' }
    ])

    this.getPage().then(({ data }) => {
      this.page.title = data.title
      this.page.slug = data.slug
      this.page.configuration = data.configuration

      this.isPageIsLoaded = true
    })

    this.getPosts().then(({ data }) => {
      this.posts = [...data['hydra:member']]
    })

    this.getProducts().then(({ data }) => {
      this.products = [...data['hydra:member']]
      this.isProductsLoaded = true
    })

    this.getBrands('/brands').then(({ data }) => {
      this.brands = [...data['hydra:member']]
      this.isBrandsLoaded = true
    })
  },
  methods: {
    async getPage() {
      try {
        return await ApiService.get('/pages', this.$route.params.id)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getPosts() {
      try {
        return await ApiService.get('/posts')
      } catch (e) {
        throw new Error(e)
      }
    },
    async getProducts() {
      try {
        return await ApiService.get('/products', '?itemsPerPage=2000')
      } catch (e) {
        throw new Error(e)
      }
    },
    async getBrands(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async update() {
      await ApiService.update('/pages', this.$route.params.id, this.page).then(
        () => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Edit success!'
          })
        }
      )
    },
    toggle(slug) {
      this.activeBlock = slug
    },
    remove(key) {
      this.page.configuration.section.splice(key, 1)
    },
    addBlock(slug) {
      this.page.configuration.section.push({
        name: slug.replace('-', ' '),
        slug: slug,
        payload: {
          title:
            slug === 'products-slider' ||
            slug === 'quick-search' ||
            slug === 'product-categories' ||
            slug === 'brands' ||
            slug === 'latest-news'
              ? ''
              : undefined,
          subtitle:
            slug === 'products-slider' ||
            slug === 'quick-search' ||
            slug === 'product-categories' ||
            slug === 'brands' ||
            slug === 'latest-news'
              ? ''
              : undefined,
          entities:
            slug === 'main-slider' ||
            slug === 'products-slider' ||
            slug === 'quick-search' ||
            slug === 'brands' ||
            slug === 'product-categories'
              ? []
              : undefined
        }
      })
    },
    onChangeList({ source, destination, elementKey }) {
      this.products = source
      this.page.configuration.section[elementKey].payload.entities = destination
    },
    onBrandChangeList({ source, destination, elementKey }) {
      this.brands = source
      this.page.configuration.section[elementKey].payload.entities = destination
    },
    titleCase(str) {
      const splitStr = str.toLowerCase().split(' ')
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      return splitStr.join(' ')
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  }
}
</script>

<style>
.jsoneditor-vue {
  height: 600px;
}
.list-group-item {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.42rem;
}

flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  /*cursor: move;*/
}
.list-group-item i {
  cursor: pointer;
}
.list-group-item + .list-group-item {
  border-top-width: 1px;
}
.list-group-item:first-child {
  border-top-right-radius: 0.42rem;
  border-top-left-radius: 0.42rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
}
</style>
